
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import ErrorDisplay from '../components/ErrorDisplay'
import Layout from '../components/Layout'

const Custom404 = () => {
  return (
    <Layout>
      <ErrorDisplay statusCode={404} />
    </Layout>
  )
}

export default Custom404


    export async function getStaticProps(ctx) {
        
        
        return {
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/404',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              
            }))
          }
        }
    }
  