import { Container } from 'react-bootstrap'
import { useTranslation } from '../../services/translation'

const ErrorDisplay = ({ statusCode }) => {
  const { t } = useTranslation()

  const title =
    statusCode === 404
      ? t('404.tit')
      : statusCode
      ? `An error ${statusCode} occurred on server`
      : 'An error occurred on client'

  return (
    <>
      <div className='b-inter'>
        <h1>{title}</h1>
      </div>
      <Container>
        <p>{t('404.txt')}</p>
        <div className='text-center'>
          <img
            className='img-fluid'
            src='https://farm1.static.flickr.com/424/31399511250_5c6a6f4519.jpg'
          />
        </div>
      </Container>
    </>
  )
}

export default ErrorDisplay
